import { device } from 'src/styles/breakpoints'
import { orange } from 'src/styles/colors'
import styled from 'styled-components'

export const Card = styled.div`
  position: relative;
  right: 12px;
  border-radius: 0 12px 12px 0;
  padding: 0 16px 0 32px;
  h3 {
    margin-bottom: 13px;
  }

  @media ${device.desktopLG} {
    min-height: 147px;
  }
`

export const NumBox = styled.div`
  position: relative;
  z-index: 2;
  min-width: 44px;
  height: 44px;
  border-radius: 50%;
  background-color: ${orange.extra};
  span {
    font-family: "Sora", Helvetica, sans-serif;
  }
`

export const Line = styled.div`
  position: relative;
  &::after {
    content: "";
    height: 74%;
    width: 1px;
    position: absolute;
    background-color: ${orange.extra};
    z-index: 1;
    top: 56px;
    left: 21px;
  }
  div {
    div {
      div:first-child ${Card} {
        border-top-left-radius: 12px;
      }
      div:last-child ${Card} {
        border-bottom-left-radius: 12px;

      }
    }
  }
`

export const TabletLineDiv = styled.div`

`
